<template>
    <ClicColInterno :cp="cp">
        <div class="form-group left-label">
            <label class="form-label">
                {{ $storage.getTextoTraduzido(label) }}
            </label>
            <div class="form-control-wrap">
                <b-form-checkbox v-model="valueModelSwitch" style="height: 1.806rem;" switch :disabled="disabled"
                    :class="this.class" :style="style" @change="change" @input="input">
                </b-form-checkbox>
            </div>
        </div>
    </ClicColInterno>
</template>

<script>

import ComponentMixin from '../../core/ComponentMixin';

export default {
    name: 'ClicSwitch',
    mixins: [ComponentMixin],

    data() {
        return {
            public: {
                isValid:        this.validateField,
                clearField:     this.clearField,

                undo:           this.undo,
                apply:          this.apply,
                isValueChanged: this.isValueChanged,
            },

            dictionaryValue: [],
            valueModelSwitch: this.cp.props.value || false,
            value: this.cp.props.value || false,
            oldValue: this.cp.props.value || false,

        }
    },
    watch: {
        valueModelSwitch(newValue) {
            const newValueStr = newValue.toString();
            if (this.value !== this.dictionaryValue[newValueStr]) {
                this.value = this.dictionaryValue[newValueStr];
            }
        },

        value(newValue) {
            if (newValue !== undefined && this.valueModelSwitch !== newValue) {
                this.valueModelSwitch = this.dictionaryModelValue[newValue];
            }
        }
    },

    mounted() {
        this.oldValue = this.value;
        this.dictionaryValue = {
            "true": this.trueValue || true,
            "false": this.falseValue || false,
        }

        this.dictionaryModelValue = {}
        this.dictionaryModelValue[this.trueValue || "true"] = true
        this.dictionaryModelValue[this.falseValue || "false"] = false
        this.dictionaryModelValue["false"] = false
        this.dictionaryModelValue["true"] = true

    },


    methods: {

        change(checked) {
            if (this.lodash.has(this.cp, 'events.change')) {
                try {
                    eval(this.cp.events.change);
                } catch (e) {
                    console.error(`[ClicSwitch] Falha ao executar evendo 'change'. Detalhes: ${e}`);
                }
            }
        },

        input(checked) {
            if (this.lodash.has(this.cp, 'events.input')) {
                try {
                    eval(this.cp.events.input);
                } catch (e) {
                    console.error(`[ClicSwitch] Falha ao executar evendo 'input'. Detalhes: ${e}`);
                }
            }
        }
    },
};
</script>