<template>

  <div class="clic-body bg-lighter npc-default has-sidebar">
    <div class="clic-app-root">
      <div class="clic-main" title="">
        <div class="clic-wrap">

          <ClicNavBar id="ClicTopNavBar" idTela="mainFramework" :childrens="this.$navbar" :hideMenuIcon="true" />
          <div class="clic-content-geral">
            <h5 v-if="$route.meta.title">
              {{ $storage.getTextoTraduzido($route.meta.title) }}
            </h5>
            <h6 class="subtitle p-1" v-if="$route.meta.subtitle">
              {{ $storage.getTextoTraduzido($route.meta.subtitle) }}
            </h6>
            <MotorS :cp="this.$route" :childrens="this.$route.meta.childrens" :idTela="this.idTela" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import Vue from 'vue';
import ClicMainSideMenu from '../../components/clicMainSideMenu/ClicMainSideMenu.vue';

export default {
  name: 'LayoutTopoConteudo',
  watch: {
    '$route'(to, from) {
      this.register();
    }
  },
  components: {
    ClicMainSideMenu,
  },

  data() {
    return {
      idTela : this.$route.name
    };
  },

  created() {
    if(this.$scripts){
      for(let module of this.$scripts){
        Vue.prototype[module.id] = eval(module.script);
      };
    };
    if (this.lodash.has(this.$route, 'meta.events.created')) {
      try {
        eval(this.lodash.get(this.$route, 'meta.events.created'))
      } catch (e) {
        console.error(`[LayoutTopoConteudo] Falha ao executar evento 'created'. Detalhes: ${e}`);
      }
    }
  },

  mounted() {
    if (this.lodash.has(this.$route, 'meta.events.mounted')) {
      try {
        eval(this.lodash.get(this.$route, 'meta.events.mounted'))
      } catch (e) {
        console.error(`[LayoutTopoConteudo] Falha ao executar evento 'mounted'. Detalhes: ${e}`);
      }
    }
  },

  updated() {
    if (this.lodash.has(this.$route, 'meta.events.updated')) {
      try {
        eval(this.lodash.get(this.$route, 'meta.events.updated'));
      } catch (e) {
        console.error(`[LayoutTopoConteudo] falha na execução do evento 'updated'. Detalhes: ${e}`);
      }
    }
  },

  methods: {

    register() {
      if (!this.$store.hasModule(this.idTela)) {
        this.$store.registerModule(`${this.idTela}`, {
          namespaced: true,
          state(cp) {
            return {
              propriedades: {},
            }
          },
          getters: {
            getPropriedades(state) {
              return state.propriedades
            },
          },
          mutations: {
            setPropriedades(state, propriedades) {
              if (propriedades) {
                state.propriedades = { ...state.propriedades, ...propriedades };
              }
            },
          },
        })
      }
    },

    $getComponents() {
      return this.$getPropriedades(this);
    },

    $getNavbarComponents(){
      return this.$store.getters[`mainFramework/getPropriedades`];
    },

  }
};
</script>

<style>
.header-logo{
  display: block !important;
}
</style>