<template>
  <div class="container">
    <b-modal hide-footer hide-header ref="modalCodeReader" size="xl" no-close-on-backdrop no-close-on-esc centered
      body-class="p-0" @hidden="onModalClose()">
      <div class="camera-fullscreen">
        <WebCam id="webcam-codereader" ref="webcam" :device-id="deviceId" @started="onStarted" @error="onError"
          @cameras="onCameras" @notsupported="onNotsupported" @camera-change="onCameraChange" />
        <div class="div-select-camera">
          <select v-if="devices.length > 1" class="select-camera" v-model="camera">
            <option v-for="device in devices" :key="device.deviceId" :value="device.deviceId">
              {{ device.label }}
            </option>
          </select>
          <select v-if="scannerOptions.length > 1" class="select-camera" v-model="readType" @change="onScanTypeChange">
            <option v-for="scannOption in scannerOptions" :key="scannOption" :value="scannOption">
              {{ scanTypeDescriptions[scannOption] }}
            </option>
          </select>
          <b-button @click="closeModal()" class="button-camera" variant="danger">Encerrar</b-button>

        </div>
      </div>
    </b-modal>
  </div>
</template>


<script>
import { BrowserQRCodeReader } from '@zxing/browser';
import Quagga from 'quagga';
import ComponentMixin from '../../core/ComponentMixin';
import { WebCam } from "vue-web-cam";

export default {
  name: "ClicCodeReader",
  mixins: [ComponentMixin],
  components: { WebCam },
  data() {
    return {
      public: {
        startScan: this.startScan
      },
      img: null,
      camera: null,
      deviceId: null,
      qrCodeControls: null,
      devices: [],
      qrCodeReader: new BrowserQRCodeReader(),
      scanner: [],
      readType: "",
      barCodeIsRunning: false,
      scanTypeDescriptions: {
        QRCODE: "QR Code",
        BARCODE: "Barras"
      },

    };
  },
  created() {
    this.scannerOptions = this.scannerOptions?.split(" ") || []
    this.readType = this.scannerOptions[0] ? this.scannerOptions[0] : ""

  },
  computed: {
    device: async function () {
      return this.devices.find(n => n.deviceId === this.deviceId);
    }
  },
  watch: {
    camera: function (id) {
      this.deviceId = id;
    },
    devices: async function () {
      try {
        let stream = await navigator.mediaDevices.getUserMedia({
          video: { facingMode: { exact: "environment" } }
        })

        const [videoTrack] = stream.getVideoTracks();
        this.camera = videoTrack.getSettings().deviceId;
        this.deviceId = videoTrack.getSettings().deviceId;
      } catch (error) {
        const [first, ...tail] = this.devices.slice().reverse();
        if (first) {
          this.camera = first.deviceId;
          this.deviceId = first.deviceId;
        }
      }
    }
  },


  methods: {
    onCodeScan($scanned) {
      if (this.lodash.has(this.cp, 'events.onCodeScan')) {
        try {
          eval(this.cp.events.onCodeScan);
        } catch (e) {
          console.error(`[ClicCodeReader] Falha ao executar evendo 'onCodeScan'. Detalhes: ${e}`);
        }
      }
      this.onModalClose()
      this.closeModal()
    },
    onScanTypeChange() {
      this.stopBarCodeReader();
      this.stopQrReader();

      if (this.readType === "QRCODE") {
        this.initializeQrCode();
      } else if (this.readType === "BARCODE") {
        this.initializeBarCode();
      }
    },
    startScan() {
      if (this.scannerOptions && this.scannerOptions.length) {
        this.$refs.modalCodeReader.show();
      } else {
        console.log("[ClicCodeReader] Nenhuma opção de escaneamento foi habilitada. Não foi possível abrir o Scanner.")
      }
    },
    stopQrReader() {
      if (this.qrCodeControls) {
        this.qrCodeControls.stop();
      }
    },
    stopBarCodeReader() {
      if (Quagga) {
        try {
          Quagga.stop();
          Quagga.offDetected(this.onDetectedCallback);
          this.barCodeIsRunning = false
        } catch (error) {
        }
      }
    },
    stopCamera() {
      this.deviceId = ""
    },

    onModalClose() {
      this.stopCamera();
      this.stopQrReader()
      this.stopBarCodeReader();
    },
    closeModal() {
      this.$refs.modalCodeReader.hide();
      this.onModalClose();
    },

    async initializeQrCode() {
      await this.$nextTick();
      const selectedDeviceId = this.deviceId;
      const previewElem = document.querySelector('#webcam-codereader > video');

      this.qrCodeControls = await this.qrCodeReader.decodeFromVideoDevice(selectedDeviceId, previewElem, (result, error, controls) => {
        if (result) {
          this.onCodeScan({ type: "QRCODE", data: result });
        }
      });
    },

    async initializeBarCode() {
      await this.$nextTick();
      const previewElem = document.querySelector('#webcam-codereader');

      if (!this.barCodeIsRunning) {

        Quagga.init({
          inputStream: {
            name: "Live",
            type: "LiveStream",
            target: previewElem
          },
          decoder: {
            readers: [
              "code_128_reader",
              "ean_reader",
              "ean_8_reader",
              "code_39_reader",
              "code_39_vin_reader",
              "codabar_reader",
              "upc_reader",
              "upc_e_reader",
              "i2of5_reader",
              "2of5_reader",
              "code_93_reader",
            ]
          },
          configs: {
            numOfWorkers: 16,
          }
        }, (err) => {
          if (err) {
            console.log(err);
            return
          }
          Quagga.start();
          this.onDetectedCallback = (barCode) => {
            this.onCodeScan({ type: "BARCODE", data: barCode });
          };
          Quagga.onDetected(this.onDetectedCallback);

        });
        this.barCodeIsRunning = true;
      }
    },

    onStarted() {
      this.onScanTypeChange();
    },
    onError(error) {
      console.log("[ClicCodeReader] Não foi possível iniciar a câmera. ", error);
    },
    onNotsupported() {
      console.log("[ClicCodeReader] Câmera não suportada neste dispositivo.")
    },
    onCameras(cameras) {
      this.devices = cameras;
    },
    onCameraChange(deviceId) {
      this.deviceId = deviceId;
      this.camera = deviceId;
    }
  }
}
</script>

<style scoped>
.camera-fullscreen {
  height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.div-select-camera {
  position: absolute;
  bottom: 10px;
  align-items: center;
  display: flex;
}

.select-camera {
  border-radius: 5px;
  padding: 5px;
  background-color: #f3f3f3ed;
  max-width: 120px;
  border: none;
  margin-left: 5px;
}

.button-camera {
  border-radius: 5px;
  border: none;
  margin-left: 5px;
}

#webcam-codereader {
  height: 100%;
  object-fit: cover;
  /* Ocupa o espaço disponível */
}
</style>
