<template>
    <ClicColInterno
        :cp="cp"
        :key="componentKey"
        containerStyle="height: 100%; width: 100%;"
    >
        <MonacoEditor
            ref="monacoEditor"
            :style="this.style" 
            :class="this.class"
            :width="width"      
            :height="height"     
            :value="value"      
            v-model="value"
            :original="original"   
            :language="language"   
            :theme="theme"       
            :options="options"
            @change="change"
            @editorBeforeMount="editorBeforeMount"
            @editorMounted="editorMounted"
            class="editor"
        >
        </MonacoEditor>
    </ClicColInterno>
</template>

<script>

import ComponentMixin from '../../core/ComponentMixin';
import MonacoEditor from 'monaco-editor-vue';
import * as acorn from 'acorn';
import * as walk from 'acorn-walk';

export default {
    name: 'ClicCodeEditor',
    mixins: [ComponentMixin],
    components: {
        MonacoEditor
    },
    data(){
        return {
            public : {
                insertTextOnMouseCursor : this.insertTextOnMouseCursor,
                foldByRegex: this.foldByRegex,
                foldFunctionsAndMethods: this.foldFunctionsAndMethods,
                validateCodeAsJavascript: this.validateCodeAsJavascript
            },
            
            value : "",

            /*
            optionsx : {
                theme : "vs-dark",
                formatOnPaste : true,
                formatOnType  : true,
                tabCompletion : true,
                automaticLayout : true,
                language: 'javascript'
            }, */

        }
    },

    watch: {
        value(newValue) {
            this.value = newValue || "";
        },
    },

    mounted() {
        const monacoEditorRef = this.$refs.monacoEditor.editor;
        monacoEditorRef.onDidBlurEditorWidget(()=>{
            if  (this.lodash.has(this.cp, 'events.blur')) {
                try {
                    eval(this.cp.events.blur);
                } catch (e) {
                    console.error(`[ClicCodeEditor] Falha ao executar evento 'blur'. Detalhes: ${e}`);
                }
            }
        });
    },

    methods: {
        change(editor, monaco) {
        },

        editorBeforeMount(monaco) {
        },
        editorMounted(editor, monaco) {
        },

        insertTextOnMouseCursor(text) {
            let monacoEditorRef = this.$refs.monacoEditor.editor;
            let cursorPosition = monacoEditorRef.getPosition();

            monacoEditorRef.executeEdits("",
                [
                    {
                        range: new monaco.Range(cursorPosition.lineNumber,
                            cursorPosition.column,
                            cursorPosition.lineNumber,
                            cursorPosition.column),
                        text: text
                    }
                ]
            );
        },

        async foldByRegex(regex) {
            const monacoEditorRef = this.$refs.monacoEditor.editor;
            const model = monacoEditorRef.getModel();
            let matches = model.findMatches(regex.source, true, true, false, null, true);
            for (let match of matches) {
                await monacoEditorRef.setPosition({ lineNumber: match.range.startLineNumber, column: 1 });
                await monacoEditorRef.getAction('editor.fold').run();
            }
        },

        async foldFunctionsAndMethods() {
            const monacoEditorRef = this.$refs.monacoEditor.editor;
            const lineNumbers = [];
            const parsed = acorn.parse(this.value, { locations: true });

            walk.simple(parsed, {
                FunctionDeclaration(node) {
                    lineNumbers.push(node.loc.start.line);
                },
                MethodDefinition(node) {
                    lineNumbers.push(node.loc.start.line);
                }
            });

            for (const lineNumber of lineNumbers) {
                await monacoEditorRef.setPosition({ lineNumber, column: 1 });
                await monacoEditorRef.getAction('editor.fold').run();
            }
        },

        validateCodeAsJavascript() {
            try {
                acorn.parse(this.value, { ecmaVersion: 'latest' });
                return { valid: true, error: null };
            } catch (error) {
                return { valid: false, error: error.message };
            }
        }


    }
};
</script>

<style lang="scss">
    .editor{
        text-align: left;
        width: 100%;
        height: 100%;
    }

    .monaco-editor .parameter-hints-widget {
        border: 0px;
    }
    .monaco-editor .parameter-hints-widget .signature {
        padding: 0px;
    }
    .monaco-editor .suggest-widget {
        border: 0px;
    }
    .monaco-editor.vs-dark .suggest-widget {
        border: 0px;
    }
    .monaco-editor .rename-box {
        top: 0;
    }
</style>



