import ClicRow from '../components/clicGridLayout/ClicRowModel';
import ClicCol from '../components/clicGridLayout/ClicColModel';
import ClicButton from '../components/clicButton/ClicButtonModel';
import ClicMultiselect from '../components/clicMultiselect/ClicMultiselectModel';
import ClicCard from '../components/clicCard/ClicCardModel';
import ClicCheckbox from '../components/clicCheckbox/ClicCheckboxModel';
import ClicDatePicker from '../components/clicDatePicker/ClicDatePickerModel';
import ClicDropdown from '../components/clicDropdown/ClicDropdownModel';
import ClicDropdownItem from '../components/clicDropdown/ClicDropdownItemModel';
import ClicHint from '../components/clicHint/ClicHintModel';
import ClicIcon from '../components/clicIcon/ClicIconModel';
import ClicIFrame from '../components/clicIFrame/ClicIFrameModel';
import ClicImage from '../components/clicImage/ClicImageModel';
import ClicInputFile from '../components/clicInputFile/ClicInputFileModel';
import ClicProgressBar from '../components/clicProgressBar/ClicProgressBarModel';
import ClicInput from '../components/clicInput/ClicInputModel';
import ClicInputCurrencyMaskModel from '../components/clicInput/clicInputCurrencyMask/ClicInputCurrencyMaskModel';
import ClicInputMaskModel from '../components/clicInput/clicInputMask/ClicInputMaskModel';
import ClicCodeEditor from '../components/clicCodeEditor/ClicCodeEditorModel';
import ClicJson from '../components/clicJson/ClicJsonModel';
import ClicToolbar from '../components/clicToolbar/ClicToolbarModel';
import ClicTreeView from '../components/clicTreeView/ClicTreeViewModel';
import ClicText from '../components/clicText/ClicTextModel';
import ClicTabs from '../components/clicTabs/ClicTabsModel';
import ClicTab from '../components/clicTabs/ClicTabModel';
import ClicModal from '../components/clicModal/ClicModalModel';
import ClicModalFooter from '../components/clicModal/ClicModalFooterModel';
import ClicSwitch from '../components/clicSwitch/ClicSwitchModel';
import ClicTable from '../components/clicTable/ClicTableModel';
import ClicTableHeader from '../components/clicTable/ClicTableHeaderModel';
import ClicTableColumnText from '../components/clicTable/ClicTableColumnTextModel';
import ClicTableColumnCustom from '../components/clicTable/ClicTableColumnCustomModel';
import ClicTableColumnDetail from '../components/clicTable/ClicTableColumnDetailModel';
import ClicList from '../components/clicList/ClicListModel';
import ClicChart from '../components/clicChart/ClicChartModel';
import ClicTextArea from '../components/clicTextArea/ClicTextAreaModel';
import ClicMarkdown from '../components/clicMarkdown/ClicMarkdownModel';
import ClicNotification from '../components/clicNotificacao/ClicNotificationModel';
import ClicSwagger from '../components/clicSwagger/ClicSwaggerModel';
import ClicCatalog from '../components/clicCatalog/ClicCatalogModel';
import ClicCollapse from '../components/clicCollapse/ClicCollapseModel';
import ClicInputGroup from '../components/clicInputGroup/ClicInputGroupModel';
import ClicCalendar from '../components/clicCalendar/ClicCalendarModel';
import ClicCronBuilder from '../components/clicCronBuilder/ClicCronBuilderModel';
import ClicBreadcrumb from '../components/clicBreadcrumb/ClicBreadcrumbModel';
import ClicCodeReader from '../components/clicCodeReader/ClicCodeReaderModel';
import ClicCodeDiff from '../components/clicCodeReader/ClicCodeReaderModel';
import ClicMongoDBChart from '../components/clicMongoDBChart/ClicMongoDBChartModel';

import LayoutMenuTopoConteudo from '../views/layouts/LayoutMenuTopoConteudoModel';
import LayoutTopoConteudo from '../views/layouts/LayoutTopoConteudoModel';
import LayoutVazio from '../views/layouts/LayoutVazioModel';


import ComponentModel from './ComponentModel';

const getAllComponentsProperties = () => {
    let retorno = [];

    retorno.push(
        LayoutMenuTopoConteudo,
        LayoutTopoConteudo,
        LayoutVazio,

        ClicButton,
        ClicCard,
        ClicChart,
        ClicCatalog,
        ClicCollapse,
        ClicCheckbox,
        ClicCodeEditor,
        ClicCol,
        ClicDatePicker,
        ClicDropdown,
        ClicDropdownItem,
        ClicHint,
        ClicIcon,
        ClicIFrame,
        ClicImage,
        ClicInputFile,
        ClicProgressBar,
        ClicInput,
        ClicInputCurrencyMaskModel,
        ClicInputMaskModel,
        ClicJson,
        ClicList,
        ClicModal,
        ClicModalFooter,
        ClicMultiselect,
        ClicSwitch,
        ClicTab,
        ClicTable,
        ClicTableHeader,
        ClicTableColumnText,
        ClicTableColumnCustom,
        ClicTableColumnDetail,
        ClicTabs,
        ClicText,
        ClicToolbar,
        ClicTreeView,
        ClicRow,
        ClicTextArea,
        ClicMarkdown,
        ClicNotification,
        ClicSwagger,
        ClicInputGroup,
        ClicCalendar,
        ClicCronBuilder,
        ClicBreadcrumb,
        ClicCodeReader,
        ClicCodeDiff,
        ClicMongoDBChart,
    )

    return Object.assign({}, ...injectComponentGenericAtributes(retorno));
}

const injectComponentGenericAtributes = (componentes) => {
    for (let componente of componentes) {
        let nomeComponente = Object.keys(componente)[0];
        let estrutura = componente[nomeComponente];
        
        estrutura.props     = {...ComponentModel.props, ...estrutura.props || {}};
        estrutura.events    = {...ComponentModel.events, ...estrutura.events || {}};
    }
    return componentes;
}

export default getAllComponentsProperties