<template>
  <ClicColInterno :cp="cp" :key="componentKey">
    <div class="form-group left-label">
      <div class="d-flex flex-row align-items-start">
        <label class="form-label form-label-clic" v-if="label">
          <b v-if="required && (value == undefined || value === '')" class="text-danger">*</b>
          {{ $storage.getTextoTraduzido(label) }}
        </label>
        <ClicHintInterno v-if="hintText" :cp="cp" />
      </div>

      <b-form-datepicker v-bind="labels[locale] || {}" :locale="locale" :v-slot="lodash.get(cp, 'props.vSlot')"
        :style="style" :class="this.class" :aria-controls="ariaControls" :boundary="boundary" :button-only="buttonOnly"
        :button-variant="buttonVariant" :calendar-width="calendarWidth" :close-button="closeButton"
        :close-button-variant="closeButtonVariant" :dark="dark" :dateDisabledFn="dateDisabledFn"
        :date-format-options="parseStringToJsonObject(dateFormatOptions)" :direction="direction" :disabled="disabled"
        :dropleft="dropleft" :dropright="dropright" :dropup="dropup" :form="form" :header-tag="headerTag"
        :hide-header="hideHeader" :initial-date="initialDate" :max="max" :menu-class="menuClass" :min="min" :name="name"
        :nav-button-variant="navButtonVariant" :no-close-on-select="noCloseOnSelect" :no-flip="noFlip"
        :no-highlight-today="noHighlightToday" :offset="offset" :placeholder="placeholder" :popper-opts="popperOpts"
        :readonly="readonly" :required="required" :reset-button="resetButton == undefined ? true : resetButton"
        :reset-button-variant="resetButtonVariant" :reset-value="resetValue" :right="right"
        :selected-variant="selectedVariant" :show-decade-nav="showDecadeNav" :size="size" :start-weekday="startWeekday"
        :state="fieldState" :today-button="todayButton" :today-button-variant="todayButtonVariant"
        :today-variant="todayVariant" v-model="value" :value-as-date="valueAsDate"
        :weekday-header-format="weekdayHeaderFormat" @context="context" @hidden="hidden" @input="input" @shown="shown">
      </b-form-datepicker>
      <b-form-invalid-feedback v-if="invalidFeedbackMessage">
        {{ invalidFeedbackMessage }}
      </b-form-invalid-feedback>

    </div>
  </ClicColInterno>
</template>

<script>

import ComponentMixin from '../../core/ComponentMixin';

export default {
  name: 'ClicDatePicker',
  mixins: [ComponentMixin],

  data() {
    return {
      public: {
        isValid: this.validateField,
        clearField: this.clearField
      },
      fieldState: undefined,
      value: undefined,
      locale: 'pt-BR',
      labels: {
        'pt-BR': {
          labelPrevDecade: 'Década anterior',
          labelPrevYear: 'Ano anterior',
          labelPrevMonth: 'Mês anterior',
          labelCurrentMonth: 'Mês atual',
          labelNextMonth: 'Próximo mês',
          labelNextYear: 'Próximo ano',
          labelNextDecade: 'Próxima década',
          labelToday: 'Hoje',
          labelSelected: 'Data selecionada',
          labelNoDateSelected: '',
          labelCalendar: 'Calendário',
          labelNav: 'Navegação do calendário',
          labelHelp: 'Use as setas para navegar entre as datas',
          labelResetButton: 'Limpar data selecionada'
        },
      }
    };
  },
  methods: {
    input(date) {
      if (!this.fieldState || this.fieldState) {
        this.fieldState = undefined;
      };
      if (this.lodash.has(this.cp, 'props.validateScript')) {
        this.validateField();
      };
      if (this.lodash.get(this.cp, 'events.input')) {
        try {
          eval(this.cp.events.input);
        } catch (e) {
          console.error(`[ClicDatePicker] Falha ao executar evento 'input'. Detalhes: ${e}`);
        }
      }
    },

    context(context) {
      if (this.lodash.get(this.cp, 'events.context')) {
        try {
          eval(this.cp.events.context);
        } catch (e) {
          console.error(`[ClicDatePicker] Falha ao executar evento 'context'. Detalhes: ${e}`);
        }

      }
    },

    hidden() {
      if (this.lodash.get(this.cp, 'events.hidden')) {
        try {
          eval(this.cp.events.hidden);
        } catch (e) {
          console.error(`[ClicDatePicker] Falha ao executar evento 'hidden'. Detalhes: ${e}`);
        }

      }
    },

    shown() {
      if (this.lodash.get(this.cp, 'events.show')) {
        try {
          eval(this.cp.events.show);
        } catch (e) {
          console.error(`[ClicDatePicker] Falha ao executar evento 'show'. Detalhes: ${e}`);
        }

      }
    },

    parseStringToJsonObject(string) {
      if (string) {
        const jsonString = string.replace(/'/g, '"');
        try {
          return JSON.parse(jsonString);
        } catch (error) {
          return string;
        }
      }
      return null
    }

  }
};
</script>

<style>
.dropdown-menu.show {
  z-index: 1010;
}

.b-calendar-grid-body .btn {
  justify-content: center;
}
</style>