import GeneralProperties from '../../core/ComponentDocs'

export default {
    documentation : {
        ...GeneralProperties.documentation,

        onCodeScan      : {
            hintTitle: "onCodeScan",
            hintText:"Para acessar o objeto escaneado utilize a variável $scanned"
        },
        
      
    }
}









