<template>
  <div class="clic-header clic-header-fixed is-light header-toolbar">
    <div class="container-fluid">
      <div class="clic-header-wrap">
        <div :class="`clic-header-brand d-xl-none `">
          <a href="/" class="logo-link">
            <img class="logo-light logo-img" :src="logoAppSrc" alt="logo">
            <img class="logo-dark logo-img" :src="logoAppSrc" alt="logo-dark">
          </a>
        </div>
        <b-row align-h="end" style="width: 100%;">
          <MotorS
            :cp="{id}"
            :childrens="childrens || []" 
            :idTela="idTela"
          />  
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>

export default {

  props: {
    id:{
      type : String
    },
    idTela:{
      type: String
    },
    childrens : {
      type: Array
    },
    hideMenuIcon : { 
      type: Boolean
    }
  },

  data() {
    return {
      logoAppSrc : `${process.env.VUE_APP_BUCKET_URL}/${this.$host.subdominio}/imagens/identidade/logoapp.png`
    };
  },
  
  created() {
    //this.$store.commit(`${this.idTela}/setPropriedades`, {[`${this.id}`]: this.cp});
    this.$store.toggleMenu = this.toggleMenu;

    if(this.hideMenuIcon){
      this.childrens = this.childrens.filter((obj) => obj.id != "menuToggleButton")
    }
  },

  mounted() {

    this.$root.$on('clic:menu:toggle', () => {
      let menu = document.getElementById("clic-fw-menu-lateral");
      let isJustShown = menu.classList.contains('clic-sidebar-active');

      

      if  (isJustShown) {
        menu.classList.remove("clic-sidebar-active");
        //navigation.classList.toggle('active');
      } else {
        menu.classList.toggle("clic-sidebar-active");
        //navigation.classList.remove("active");
      }
    });
  },

  methods: {

    toggleMenu() {
      this.$root.$emit('clic:menu:toggle');
    },

  }

};
</script>
<style>
/*
.header-toolbar{
  background-color: white !important;
  box-shadow: 0 2px 9px rgba(0, 0, 0, 0.1) !important;
  border-bottom: 1px solid #eaeaea;
}
  */

</style>