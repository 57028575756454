import ClicColInterno from '../clicGridLayout/ClicColInternoModel';
import ClicHintInterno from '../clicHint/ClicHintInternoModel';

import ClicMultiselectDocs from './ClicMultiselectDocs';

export default {
    ClicMultiselect : {
        config      : {isContainer: true, icon: "md-formatlistnumbered-round", documentation: ClicMultiselectDocs.documentation },
        component   : {type: 'string', component: { name : '' }, disabled:true},
        id          : {type: 'string', component: { name : '' }},
        props : {
            label                   : {type: 'string',     default: undefined,     component: { name : 'input' } },
            responsePath            : {type: 'string',     default: undefined,     component: { name : 'input' } },
            labels                  : {type: 'string',     default: undefined,     component: { name : 'input' } },
            axios                   : {type: 'string',     default: undefined,     component: { name : 'code' } },
            invalidFeedbackMessage  : {type: 'string',     default: undefined,     component: { name : 'input' } },
            validateScript          : {type: 'string',     default: undefined,     component: { name : 'code'  } },
            required                : {type: 'boolean',    default: undefined,     component: { name : 'switch'} },
            fieldState              : {type: 'boolean',    default: undefined,     component: { name : 'switch'} },
            jsonId                  : {type: 'string',     default: undefined,     component: { name : 'input' } },
            searchDelayInMs         : {type: 'number',     default: undefined,     component: { name : 'input' } },
            searchAtributeAxiosName : {type: 'string',     default: undefined,     component: { name : 'input' } },

            options                 : {type: 'array',       default: [],            component: { name : 'code' } },
            value                   : {type: 'any',         default: '',            component: { name : 'input' } },
            multiple                : {type: 'boolean',     default: undefined,     component: { name : 'switch' } },
            trackBy                 : {type: 'string',      default: undefined,     component: { name : 'input' } },
            searchable              : {type: 'boolean',     default: undefined,     component: { name : 'switch' } },
            clearOnSelect           : {type: 'boolean',     default: undefined,     component: { name : 'switch' } },
            hideSelected            : {type: 'boolean',     default: undefined,     component: { name : 'switch' } },
            placeholder             : {type: 'string',      default: 'selecione',   component: { name : 'input'  } },
            allowEmpty              : {type: 'boolean',     default: undefined,     component: { name : 'switch' } },
            resetAfter              : {type: 'boolean',     default: undefined,     component: { name : 'switch' } },
            closeOnSelect           : {type: 'boolean',     default: undefined,     component: { name : 'switch' } },
            customLabel             : {type: 'function',    default: undefined,     component: { name : 'code' } },
            taggable                : {type: 'boolean',     default: undefined,     component: { name : 'switch' } },
            tagPlaceholder          : {type: 'string',      default: '',            component: { name : 'input' } },
            tagPosition             : {type: 'string',      default: undefined,     component: { name : 'input' } },
            max                     : {type: 'number',      default: undefined,     component: { name : 'input' } },
            optionsLimit            : {type: 'number',      default: 20,            component: { name : 'input' } },
            groupValues             : {type: 'string',      default: undefined,     component: { name : 'input' } },
            groupLabel              : {type: 'string',      default: undefined,     component: { name : 'input' } },
            groupSelect             : {type: 'boolean',     default: undefined,     component: { name : 'switch' } },
            blockKeys               : {type: 'array',       default: undefined,     component: { name : 'code' } },
            internalSearch          : {type: 'boolean',     default: undefined,     component: { name : 'switch' } },
            preserveSearch          : {type: 'boolean',     default: undefined,     component: { name : 'switch' } },
            preselectFirst          : {type: 'boolean',     default: undefined,     component: { name : 'switch' } },
            name                    : {type: 'string',      default: '',            component: { name : 'input' } },
            selectLabel             : {type: 'string',      default: 'selecionar',  component: { name : 'input' } },
            selectGroupLabel        : {type: 'string',      default: '',            component: { name : 'input' } },
            selectedLabel           : {type: 'string',      default: 'Selecionado', component: { name : 'input' } },
            deselectLabel           : {type: 'string',      default: 'remover',     component: { name : 'input' } },
            deselectGroupLabel      : {type: 'string',      default: '',            component: { name : 'input' } },
            showLabels              : {type: 'boolean',     default: undefined,     component: { name : 'switch' } },
            limit                   : {type: 'number',      default: undefined,     component: { name : 'input' } },
            limitText               : {type: 'function',    default: undefined,     component: { name : 'code' } },
            loading                 : {type: 'boolean',     default: false,         component: { name : 'switch' } },
            disabled                : {type: 'boolean',     default: false,         component: { name : 'switch' } },
            maxHeight               : {type: 'integer',     default: undefined,     component: { name : 'input' } },
            openDirection           : {type: 'string',      default: 'auto',        component: { name : 'input' } },
            showNoResults           : {type: 'boolean',     default: true,          component: { name : 'switch' } },
            tabindex                : {type: 'number',      default: undefined,     component: { name : 'input' } },
            showPointer             : {type: 'boolean',     default: undefined,     component: { name : 'switch' } },
            optionHeight            : {type: 'number',      default: undefined,     component: { name : 'input' } },
            changeBackgroundColor   : {type: 'boolean',     default: false,         component: { name : 'switch' } },
            backgroundColorProp     : {type: 'string',      default: '',            component: { name : 'input' } },

            ...ClicColInterno,
            ...ClicHintInterno   
        },
        events : {
            input           : {type: 'string', component: { name : 'code' } },
            select          : {type: 'string', component: { name : 'code' } },
            remove          : {type: 'string', component: { name : 'code' } },         
            searchChange    : {type: 'string', component: { name : 'code' } },
            tag             : {type: 'string', component: { name : 'code' } },
            open            : {type: 'string', component: { name : 'code' } },
            close           : {type: 'string', component: { name : 'code' } },
        },
    }
}
