import estruturaComponentes from './componentsProperties';
import estruturaComponentesInternos from './componentsInternalProperties';
//import Vue from 'vue';

const componentMixin = {
   
    props: {
        cp                  : Object,
        state               : Object,
        idTela              : String,
        childrens           : Array
    },

    data() {
        return {
            componentKey    : this.$utils.getComponentID()
        };
    },

    created() {
        this.criarPropriedadesDoComponente(this.$options.name);

        // if(this.$scripts){
        //     for(let module of this.$scripts){
        //         Vue.prototype[module.id] = eval(module.script);
        //     };
        // };

        if  (this.lodash.has(this.cp, 'events.created')) {
            try {
                eval(this.cp.events.created);
            } catch (e) {
                console.error(`[${this.$options.name}] Falha ao executar o evento 'created'. Detalhes: ${e}`);
            }
        }
    },

    mounted() {        
        this.commitProperties();

        if  (this.lodash.has(this.cp, 'events.mounted')) {
            try {
                eval(this.cp.events.mounted);
            } catch (e) {
                console.error(`[${this.$options.name}] Falha ao executar o evento 'mounted'. Detalhes: ${e}`);
            }
        }
    },

    updated() {
        this.commitProperties();

        if  (this.lodash.has(this.cp, 'events.updated')) {
            try {
                eval(this.cp.events.updated);
            } catch (e) {
                console.error(`[${this.$options.name}] Falha ao executar o evento 'updated'. Detalhes: ${e}`);
            }
        }
    },

    methods: {

        $getNavbarComponents(){
            return this.$store.getters[`mainFramework/getPropriedades`];
        },

        $setAllChildrenProps(prop, value) {
            const mudarValores = (cp, prop, valor) => {
                
                const compKey          = this.lodash.get(cp, "id", cp);
                const propriedadesTela = this.$getComponents();
        
                const processarLista = (lista) => {
                    lista.forEach((item) => {
                        const listComponentsKey = Object.keys(item);
        
                        listComponentsKey.forEach((key) => {
                            this.lodash.get(item, [key, "setPropValue"], () => {})(prop, valor);
        
                            this.lodash.each(this.lodash.get(item, [key, "childrens"]), 
                                comp => mudarValores(comp, prop, valor)
                            );
                        });
                    });
                };
        
                if (propriedadesTela[compKey] && propriedadesTela[compKey].$getRowComponents) {
                    const propriedadesLista = propriedadesTela[compKey].rows;
                    processarLista(propriedadesLista);

                } else {
                    this.lodash.get(propriedadesTela, [compKey, "setPropValue"], () => {})(prop, valor);
                    
                    this.lodash.each(this.lodash.get(propriedadesTela, [compKey, "childrens"]), 
                        comp => mudarValores(comp, prop, valor)
                    );
                }
            };
        
            mudarValores(this.cp, prop, value);
        },
        

        $getComponents() {
            return this.$getPropriedades(this);
        },

        $getComponentsPromise() {
            return new Promise((resolve, reject) => {
                for (let tentativa=0; tentativa < 20; tentativa++) {
                    if  (this.$getPropriedades(this)) {
                        return resolve(this.$getComponents(this));
                    } else {
                        setTimeout(() => $getComponents(), 50);
                    }
                }
                resolve(undefined);
            });  
        },

        $toggleRowDetails(){
            // this.$applyRowComponents(this.state.row);

            // Quando um input tem o valor alterado, caso feche e abra novamente os detalhes da row, o item perde o valor.
            // criar solução

            this.state.row.toggleDetails();
        },

        $getRowComponents(row) {
            let propriedadesTela = this.$store.getters[`${this.idTela}/getPropriedades`];
            let propriedadesComponentePai = propriedadesTela[this.state.parent.id];

            let componentesRow = propriedadesComponentePai.rows || [];
            return componentesRow[row || this.rowIndex] || {};
        },

        $applyRowComponents(row){
            const components = this.$getRowComponents(row);
            for (const comp in components) {
                if  (this.isInputComponent(components[comp].component)) {                    
                    if  (components[comp].apply) {
                        components[comp].apply();
                    }
                }
            }
        },

        $undoRowComponents(row){
            const components = this.$getRowComponents(row);
            for (const comp in components) {
                if  (this.isInputComponent(components[comp].component)) {                    
                    if  (components[comp].undo) {
                        components[comp].undo();
                    }
                }
            }
        },

        $hasRowComponentsValueChanged(row) {
            let changed = false;
            const components = this.$getRowComponents(row);
            for (const comp in components) {
                if  (components[comp].isValueChanged) {
                    changed = changed || components[comp].isValueChanged();
                }
            }
            return changed;
        },

        $getSlot(slotId) {
            if  (this.lodash.get(this, "cp.childrens",[]).length > 0) { 
                let retorno = undefined;
                if  (slotId == "default") {
                    retorno = this.cp.childrens.filter((child) => (child.props.slot == slotId || child.props.slot == "" || child.props.slot == undefined));
                } else {
                    retorno = this.cp.childrens.filter((child) => (child.props.slot == slotId));
                }
                if  (retorno && retorno.length) {
                    return retorno;
                } else {
                    return undefined;
                }
            }
		},

        commitProperties() {            
            let defaultProps = {
                ...this.cp,
                ...this.public,
                refs: this.$refs,
                state: this.state,
                setPropValue : this.setPropValue,
                setPropEval  : this.setPropEval,
                getPropValue : this.getPropValue,
                setAllChildrenProps : this.$setAllChildrenProps
            };

            if  (this.lodash.get(this, 'state.parent.component') == 'ClicTable' || 
                this.lodash.get(this, 'state.parent.component') == 'ClicCatalog' || 
                this.lodash.get(this, 'state.parent.component') == 'ClicList') {
                this.rowIndex = (this.state.row.index + '') *1;
                
                let propriedadesTela = this.$store.getters[`${this.idTela}/getPropriedades`];
                let propriedadesTable = propriedadesTela[this.state.parent.id];
                propriedadesTable.rows = propriedadesTable.rows || [];
                propriedadesTable.rows[this.rowIndex] = propriedadesTable.rows[this.rowIndex] || {};
                propriedadesTable.rows[this.rowIndex][this.cp.id] = {
                    ...defaultProps,
                    index: this.rowIndex
                };

                this.$store.commit(`${this.idTela}/setPropriedades`, {[`${this.state.parent.id}`] : {                        
                        ...propriedadesTable,                                 
                        $getRowComponents : this.$getRowComponents,                        
                    }
                });                              

            } else if  (this.cp && Object.keys(this.cp).length && this.idTela) {
                let propsAtuais = {};
                let componentesTela = this.$store.getters[`${this.idTela}/getPropriedades`];
                let componenteAtual = componentesTela[this.cp.id];
                if  (componenteAtual) {
                    propsAtuais = componenteAtual;
                }

                this.$store.commit(`${this.idTela}/setPropriedades`, {[`${this.cp.id}`] : {
                        ...propsAtuais,
                        ...defaultProps
                    }   
                });
            }
        },

        criarPropriedadesDoComponente(nomeComponente) {
            let estruturaDoComponente = estruturaComponentes()[nomeComponente];
            if  (estruturaDoComponente) {
                let props = estruturaDoComponente.props;
                let propsNames = Object.keys(props);

                for (let prop of propsNames) {
                    //this[prop] = this.lodash.get(this.cp, 'props.'+prop, this.lodash.get(props[prop], 'default', undefined));

                    let valor = this.lodash.get(this.cp, 'props.'+prop, this.lodash.get(props[prop], 'default', undefined));

                    try {
                        let valorEval = eval(valor);
                        //this[prop] = valorEval;
                        valor = valorEval;
                    } catch (e) { }

                    //this.cp.props[prop] = valor;
                    this[prop] = valor;
                    
                    if  (estruturaComponentesInternos()[prop]) {
                        delete this[prop];
                        const internalProps = estruturaComponentesInternos()[prop];
                        const propsInternalNames = Object.keys(internalProps);
                        
                        for (let propI of propsInternalNames) {
                            this[propI] = this.lodash.get(this.cp, 'props.'+propI, this.lodash.get(internalProps, propI+'.default', undefined));                                                        
                        }
                    }
                }
            }
        },

        destroy() {
            this.$destroy();
            this.$el.parentNode.removeChild(this.$el);
        },

        reload(force=false) {
            this.$forceUpdate();
            if  (force) {
                this.componentKey = this.$utils.getComponentID();
            }
        },

        setPropValue(name, value) {
            try {
                this.cp.props[name] = value;
                this[name] = value;
                this.reload();
                
            } catch (e) {
                console.error(`[${this.$options.name} - ${this.cp.id}] Falha ao alterar a propriedade ${name}, valor: '${value}'. Detalhes: ${e}`);
            }
        },

        setPropEval(name, value) {
            try {
                const novoValor = eval(value);
                setPropValue(name, novoValor);
            } catch (e) {
                console.error(`[${this.$options.name} - ${this.cp.id}] Falha ao alterar a propriedade ${name} (EVAL), valor: '${value}'. Detalhes: ${e}`);
            }
        },

        getPropValue(name) {
            return this[name];
        },

        isInputComponent(comp){
            const component = comp || this.cp.component;
            if ( component == "ClicInput"  ||
                component == "ClicInputMask" ||
                component == "ClicInputCurrencyMask" ||
                component == "ClicMultiselect" ||
                component == "ClicDatePicker" ||
                component == "ClicTextArea" ||
                component == "ClicSwitch" ||
                component == "ClicMarkdown") {
                
                return true;
            }
            return false;
        },  

        getInputComponentsFromComponent(component){
            let retorno = [];
            const getInput = (cp) => {
                if (this.isInputComponent(cp.component)) {
                    retorno.push(cp);
                } else {
                    if (cp.childrens && cp.childrens.length > 0){
                        for (let comp of cp.childrens){
                            getInput(comp);
                        }
                    }
                }
            };
            getInput(component);
            return retorno;
        },

        clearField(){
            if (this.isInputComponent()) {
                this.value = undefined;
            }
        },

        /*
        undo() {
            this.value = this.oldValue;
        },
        apply() {
            this.oldValue = this.value;
        },
        isValueChanged() {
            return this.oldValue != this.value;
        }, */

        async validateField(){
            if(this.isInputComponent()){
                if((this.lodash.has(this.cp, "props.required") && (this.cp.props.required === true) &&  (this.lodash.has(this.cp, "props.visible") 
                    && this.cp.props.visible === true) && (this.value === undefined || this.value == ""))){
                    
                    this.fieldState             = false;
                    this.invalidFeedbackMessage = this.lodash.get(this.cp, "props.invalidFeedbackMessage", "");
                    return false;
                }
                    
                if(this.lodash.has(this.cp, 'props.validateScript') && 
                    (this.lodash.has(this.cp, "props.visible") && this.cp.props.visible === true) && this.value != undefined && this.value != ""){
                    
                    let scripts = eval(this.cp.props.validateScript),
                    isValid = false;
        
                    for(let validate of scripts){
                        try{
                            if(this.lodash.has(validate, 'script')){
                            
                            let validatePromise = () => {
                                return new Promise((resolve, reject) => {
                                    let _this = this;
                                    let formatedScript = validate.script.replaceAll("this.", "_this.");
                                    eval(formatedScript);
                                });
                            };
                            if(await validatePromise()){
                                this.fieldState = undefined;
                                isValid         = true;
                            }else{
                                this.fieldState             = false;
                                this.invalidFeedbackMessage = this.lodash.get(validate, "message", "");
                                break;
                            }
                        };
                        
                        }catch(e){
                            console.error(`${this.cp.component} > ${this.cp.id} | Ocorreu um erro durante a execução do método validateField. ${e}`);
                        }
                    }
                    return isValid;
                }else{
                    return true;
                }
            }   
        },

    },


   };
 
   export default componentMixin;