<template>
  <ClicColInterno :cp="cp" :key="componentKey" :id="componentKey">

    <b-modal id="create-cron-interval" size="lg" title="Criar Intervalo Cron" ref="cronModal">
      <b-tabs>

        <!-- <b-tab title="Segundos" disabled title-item-class="disabled-item">
          <p>Configurações de Segundos</p>
        </b-tab> -->

        <b-tab title="Minuto">
          <b-input-group class="mt-3">
            <b-input-group-prepend style="align-items: center;">
              <b-form-checkbox v-model="checkBoxATodoMinuto" @change="onCheckboxChange('minuto', 'checkBoxATodoMinuto')"
                class="checkbox-options" name="checkBoxATodoMinuto" />
              <p class="p-class-options">A todo minuto</p>
            </b-input-group-prepend>
          </b-input-group>
          <b-input-group class="mt-3">
            <b-input-group-prepend style="align-items: center;">
              <b-form-checkbox v-model="checkBoxACadaMinuto" @change="onCheckboxChange('minuto', 'checkBoxACadaMinuto')"
                class="checkbox-options" name="checkBoxACadaMinuto" />
              <p class="p-class-options">A cada</p>
              <div class="multiselects-options">
                <Multiselect v-model="valueACadaMinuto" :options="minuteOptions" label="label" track-by="label" />
              </div>
            </b-input-group-prepend>
          </b-input-group>
        </b-tab>

        <b-tab title="Hora">
          <b-input-group class="mt-3">
            <b-input-group-prepend style="align-items: center;">
              <b-form-checkbox v-model="checkBoxATodaHora" @change="onCheckboxChange('hora', 'checkBoxATodaHora')"
                class="checkbox-options" name="checkBoxATodaHora" />
              <p class="p-class-options">A toda hora</p>
            </b-input-group-prepend>
          </b-input-group>
          <b-input-group class="mt-3">
            <b-input-group-prepend style="align-items: center;">
              <b-form-checkbox v-model="checkBoxACadaHora" @change="onCheckboxChange('hora', 'checkBoxACadaHora')"
                class="checkbox-options" name="checkBoxACadaHora" />
              <p class="p-class-options">A cada</p>
              <div class="multiselects-options">
                <Multiselect v-model="valueACadaHora" :options="hourOptions" label="label" track-by="label" />
              </div>
            </b-input-group-prepend>
          </b-input-group>
          <b-input-group class="mt-3">
            <b-input-group-prepend style="align-items: center;">
              <b-form-checkbox v-model="checkBoxHorasEspecificas"
                @change="onCheckboxChange('hora', 'checkBoxHorasEspecificas')" class="checkbox-options"
                name="checkBoxHorasEspecificas" />
              <p class="p-class-options">Quando as horas forem</p>
              <div class="multiselects-options">
                <Multiselect v-model="valueHorasEspecificas" :options="hourOptions" multiple label="label"
                  track-by="label" />
              </div>
            </b-input-group-prepend>
          </b-input-group>
          <b-input-group class="mt-3">
            <b-input-group-prepend style="align-items: center;">
              <b-form-checkbox v-model="checkBoxTodaHoraEntre"
                @change="onCheckboxChange('hora', 'checkBoxTodaHoraEntre')" class="checkbox-options"
                name="checkTodaHoraEntre" />
              <p class="p-class-options">Toda hora entre</p>
              <div class="multiselects-options">
                <Multiselect v-model="valueTodaHoraEntre1" :options="hourOptions" label="label" />
              </div>
              <p class="p-class-options">e</p>
              <div class="multiselects-options">
                <Multiselect v-model="valueTodaHoraEntre2" :options="hourOptions" label="label" />
              </div>
            </b-input-group-prepend>
          </b-input-group>
        </b-tab>

        <b-tab title="Dia">
          <b-input-group class="mt-3">
            <b-input-group-prepend style="align-items: center;">
              <b-form-checkbox v-model="checkBoxTodosOsDias" @change="onCheckboxChange('dia', 'checkBoxTodosOsDias')"
                class="checkbox-options" name="checkBoxTodosOsDias" />
              <p class="p-class-options">Todos os Dias</p>
            </b-input-group-prepend>
          </b-input-group>
          <b-input-group class="mt-3">
            <b-input-group-prepend style="align-items: center;">
              <b-form-checkbox v-model="checkBoxACadaDias" @change="onCheckboxChange('dia', 'checkBoxACadaDias')"
                class="checkbox-options" name="checkBoxACadaDias" />
              <p class="p-class-options">A cada</p>
              <div class="multiselects-options">
                <Multiselect v-model="valueACadaDias" :options="daysOptions" label="label" track-by="label" />
              </div>
            </b-input-group-prepend>
          </b-input-group>
          <b-input-group class="mt-3">
            <b-input-group-prepend style="align-items: center;">
              <b-form-checkbox v-model="checkBoxDiasDaSemanaEspecificos"
                @change="onCheckboxChange('dia', 'checkBoxDiasDaSemanaEspecificos')" class="checkbox-options"
                name="checkBoxDiasDaSemanaEspecificos" />
              <p class="p-class-options">Nos seguintes dias da semana</p>
              <div class="multiselects-options">
                <Multiselect v-model="valueDiasDaSemanaEspecificos" :options="daysweekOptions" multiple label="label"
                  track-by="label" />
              </div>
            </b-input-group-prepend>
          </b-input-group>
          <b-input-group class="mt-3">
            <b-input-group-prepend style="align-items: center;">
              <b-form-checkbox v-model="checkBoxDiasDoMesEspecificos"
                @change="onCheckboxChange('dia', 'checkBoxDiasDoMesEspecificos')" class="checkbox-options"
                name="checkBoxDiasDoMesEspecificos" />
              <p class="p-class-options">Nos seguintes dias do mês</p>
              <div class="multiselects-options">
                <Multiselect v-model="valueDiasDoMesEspecificos" :options="daysOptions" multiple label="label"
                  track-by="label" />
              </div>
            </b-input-group-prepend>
          </b-input-group>
        </b-tab>

        <!-- <b-tab title="Mês" disabled title-item-class="disabled-item">
          <p>Configurações de mês</p>
        </b-tab>

        <b-tab title="Ano" disabled title-item-class="disabled-item">
          <p>Configurações de ano</p>
        </b-tab> -->
      </b-tabs>

      <template v-slot:modal-footer>
        <b-button variant="outline-secondary" class="border-0" @click="criarIntevaloCron()">
          Criar Intervalo
        </b-button>
        <b-button variant="primary" @click="closeModal()">
          Cancelar
        </b-button>
      </template>
    </b-modal>

    <div class="form-group left-label">
      <div class="form-control-wrap">
        <b-col md="12">
          <b-input-group id="group-class">
            <b-input-group-prepend style="align-items: center;">
              <b-form-input id="value" style="border-radius: 3px 0px 0px 3px !important;" v-model="value"
                class="" md="6">
              </b-form-input>
              <b-button style="border-radius: 0px 3px 3px 0px !important;" variant="primary" @click="openModal">
                <ClicIconInterno iconName="fa-regular-clock"></ClicIconInterno>
              </b-button>
            </b-input-group-prepend>
          </b-input-group>
        </b-col>
      </div>
    </div>
  </ClicColInterno>
</template>

<script>

import ComponentMixin from '../../core/ComponentMixin';
import Multiselect from 'vue-multiselect';

export default {
  name: 'ClicCronBuilder',
  components: { Multiselect },
  mixins: [ComponentMixin],
  data() {
    return {
      value: "* * * * * ? *",

      checkBoxATodaHora: true,
      checkBoxACadaHora: false,
      checkBoxHorasEspecificas: false,
      checkBoxTodaHoraEntre: false,
      valueACadaHora: [],
      valueHorasEspecificas: [],
      valueTodaHoraEntre1: [],
      valueTodaHoraEntre2: [],

      checkBoxATodoMinuto: true,
      checkBoxACadaMinuto: false,
      valueACadaMinuto: [],

      checkBoxTodosOsDias: true,
      checkBoxACadaDias: false,
      checkBoxDiasDaSemanaEspecificos: false,
      checkBoxDiasDoMesEspecificos: false,
      valueACadaDias: [],
      valueDiasDaSemanaEspecificos: [],
      valueDiasDoMesEspecificos: [],

      hourOptions: Array.from({ length: 24 }, (_, i) => ({ value: i, label: i <= 1 ? `${i} Hora` : `${i} Horas` })),
      minuteOptions: Array.from({ length: 60 }, (_, i) => ({ value: i, label: i <= 1 ? `${i} Minuto` : `${i} Minutos` })),
      daysOptions: Array.from({ length: 31 }, (_, i) => ({ value: i + 1, label: `Dia ${i + 1} do mês` })),
      daysweekOptions: [
        { value: 0, label: "Domingo" },
        { value: 1, label: "Segunda-feira" },
        { value: 2, label: "Terça-feira" },
        { value: 3, label: "Quarta-feira" },
        { value: 4, label: "Quinta-feira" },
        { value: 5, label: "Sexta-feira" },
        { value: 6, label: "Sábado" }
      ]
    };
  },
  created() {
  },
  mounted() {
  },
  methods: {
    openModal() {
      this.$refs.cronModal.show();
      this.traduzirIntervaloCron();
    },
    closeModal() {
      this.$refs.cronModal.hide();
    },
    onCheckboxChange(intervalo, selectedCheckbox) {
      switch (intervalo) {
        case "minuto":
          this.checkBoxATodoMinuto = false;
          this.checkBoxACadaMinuto = false;
          break;
        case "hora":
          this.checkBoxATodaHora = false;
          this.checkBoxACadaHora = false;
          this.checkBoxHorasEspecificas = false;
          this.checkBoxTodaHoraEntre = false;
          break;
        case "dia":
          this.checkBoxTodosOsDias = false;
          this.checkBoxACadaDias = false;
          this.checkBoxDiasDaSemanaEspecificos = false;
          this.checkBoxDiasDoMesEspecificos = false;
          break;
      }
      this[selectedCheckbox] = true;
    },

    criarIntevaloCron() {
      const cronValues = {
        cronValueSegundos: "*",
        cronValueMinutos: this.criarCronMinutos(),
        cronValueHoras: this.criarCronHoras(),
        cronValueDias: this.criarCronDias(),
        cronValueMeses: "*",
        cronValueDiasDaSemana: this.criarCronDiasDaSemana(),
        cronValueAnos: "*"
      }

      this.value = this.construirExpressao(cronValues)
    },

    construirExpressao(cronValues) {
      if (cronValues.cronValueDias !== "*") {
        cronValues.cronValueDiasDaSemana = "?";
      } else if (cronValues.cronValueDiasDaSemana !== "*") {
        cronValues.cronValueDias = "?";
      } else {
        cronValues.cronValueDias = "?";
      }

      let expressao = `${cronValues.cronValueSegundos} ${cronValues.cronValueMinutos} ${cronValues.cronValueHoras} ${cronValues.cronValueDias} ${cronValues.cronValueMeses} ${cronValues.cronValueDiasDaSemana} ${cronValues.cronValueAnos}`
      return expressao;
    },

    traduzirIntervaloCron() {
      try {
        const [cronSegundos, cronMinutos, cronHoras,
          cronDias, cronMeses, cronDiasDaSemana, cronAnos] = this.value.split(" ");

        if (cronMinutos) {
          this.definirOpcaoMinutos(cronMinutos)
        }
        if (cronHoras) {
          this.definirOpcaoHoras(cronHoras)
        }

        if (cronDias && cronDias != "?") {
          this.definirOpcaoDias(cronDias)
        } else if (cronDiasDaSemana && cronDiasDaSemana != "?") {
          this.definirOpcaoDiasDaSemana(cronDiasDaSemana)
        }
      } catch (error) {

      }
    },



    criarCronMinutos() {
      if (this.checkBoxATodoMinuto) {
        return `*`
      } else if (this.checkBoxACadaMinuto) {
        return this.obterValorACadaXMinutos()
      } else {
        return "*"
      }
    },
    criarCronHoras() {
      if (this.checkBoxATodaHora) {
        return `*`
      } else if (this.checkBoxACadaHora) {
        return this.obterValorACadaHora()
      } else if (this.checkBoxHorasEspecificas) {
        return this.obterValorHorasEspecificas()
      } else if (this.checkBoxTodaHoraEntre) {
        return this.obterValorTodaHoraEntreOPeriodo()
      } else {
        return "*"
      }
    },
    criarCronDias() {
      if (this.checkBoxTodosOsDias) {
        return `*`
      } else if (this.checkBoxACadaDias) {
        return this.obterValorACadaXDias()
      } else if (this.checkBoxDiasDoMesEspecificos) {
        return this.obterValorDiasDoMesEspecificos()
      } else {
        return "*"
      }
    },
    criarCronDiasDaSemana() {
      if (this.checkBoxDiasDaSemanaEspecificos) {
        return this.obterValorDiasDaSemanaEspecificos()
      } else {
        return "*"
      }
    },


    definirOpcaoMinutos(cronMinutos) {
      if (cronMinutos.startsWith("0/")) {
        this.intervaloACadaMinuto(cronMinutos)
        this.onCheckboxChange("minuto", 'checkBoxACadaMinuto')

      } else {
        this.onCheckboxChange("minuto", 'checkBoxATodoMinuto')
      }
    },
    definirOpcaoHoras(cronHoras) {
      if (cronHoras.startsWith("0/")) {
        this.intervaloACadaHora(cronHoras)
        this.onCheckboxChange("hora", 'checkBoxACadaHora')

      } else if (cronHoras.includes("-")) {
        this.intervaloEntreHoras(cronHoras)
        this.onCheckboxChange("hora", 'checkBoxTodaHoraEntre')

      } else if (cronHoras.includes(",") || parseInt(cronHoras)) {
        this.intervaloHorasEspecificas(cronHoras)
        this.onCheckboxChange("hora", 'checkBoxHorasEspecificas')

      } else {
        this.onCheckboxChange("hora", 'checkBoxATodaHora')
      }
    },
    definirOpcaoDias(cronDias) {
      if (cronDias.startsWith("1/")) {
        this.intervaloACadaXDias(cronDias)
        this.onCheckboxChange("dia", 'checkBoxACadaDias')

      } else if (cronDias.includes(",") || parseInt(cronDias)) {
        this.intervaloDiasDoMesEspecificos(cronDias)
        this.onCheckboxChange("dia", 'checkBoxDiasDoMesEspecificos')

      } else {
        this.onCheckboxChange("dia", 'checkBoxTodosOsDias')
      }
    },
    definirOpcaoDiasDaSemana(cronDiasDaSemana) {
      if (cronDiasDaSemana.includes(",") || parseInt(cronDiasDaSemana)) {
        this.intervaloDiasDaSemanaEspecificos(cronDiasDaSemana)
        this.onCheckboxChange("dia", 'checkBoxDiasDaSemanaEspecificos')

      } else {
        this.onCheckboxChange("dia", 'checkBoxTodosOsDias')
      }
    },


    obterValorACadaXMinutos() {
      if (this.valueACadaMinuto.value) {
        let intervalo = this.valueACadaMinuto.value
        return `0/${intervalo}`
      }
      return "*"
    },


    obterValorACadaHora() {
      if (this.valueACadaHora.value) {
        let intervalo = this.valueACadaHora.value
        return `0/${intervalo}`
      }
      return "*"
    },

    obterValorHorasEspecificas() {
      if (this.valueHorasEspecificas.length) {
        let intervalo = ""

        for (let hora of this.valueHorasEspecificas) {
          intervalo += `${hora.value},`
        }

        if (intervalo.length > 0) {
          intervalo = intervalo.slice(0, -1);
          return intervalo
        }
      }
      return "*"
    },

    obterValorTodaHoraEntreOPeriodo() {
      if (this.valueTodaHoraEntre1.value && this.valueTodaHoraEntre2.value) {
        return `${this.valueTodaHoraEntre1.value}-${this.valueTodaHoraEntre2.value}`
      }
      return "*"
    },

    obterValorACadaXDias() {
      if (this.valueACadaDias.value) {
        let intervalo = this.valueACadaDias.value
        return `1/${intervalo}`
      }
      return "*"
    },

    obterValorDiasDoMesEspecificos() {
      if (this.valueDiasDoMesEspecificos.length) {
        let intervalo = ""

        for (let dia of this.valueDiasDoMesEspecificos) {
          intervalo += `${dia.value},`
        }

        if (intervalo.length > 0) {
          return intervalo.slice(0, -1);
        }
      }
      return "*"
    },

    obterValorDiasDaSemanaEspecificos() {
      if (this.valueDiasDaSemanaEspecificos.length) {
        let intervalo = ""
        for (let dia of this.valueDiasDaSemanaEspecificos) {
          intervalo += `${dia.value},`
        }
        if (intervalo.length > 0) {
          return intervalo.slice(0, -1);
        }
      }
      return "*"
    },

    intervaloACadaMinuto(cronMinutos) {
      const intervalo = cronMinutos.split("/")[1];
      this.valueACadaMinuto = { value: intervalo, label: `${intervalo} Minutos` }
    },

    intervaloACadaHora(cronHoras) {
      const intervalo = cronHoras.split("/")[1];
      this.valueACadaHora = { value: intervalo, label: `${intervalo} Horas` }
    },

    intervaloEntreHoras(cronHoras) {
      const [inicio, fim] = cronHoras.split("-");
      this.valueTodaHoraEntre1 = { value: inicio, label: `${inicio} Horas` }
      this.valueTodaHoraEntre2 = { value: fim, label: `${fim} Horas` }
    },

    intervaloHorasEspecificas(cronHoras) {
      const horas = cronHoras.split(",").map(hora => hora.trim());
      this.valueHorasEspecificas = []

      for (let hora of horas) {
        this.valueHorasEspecificas.push({ value: hora, label: `${hora} Horas` })
      }
    },

    intervaloACadaXDias(cronDias) {
      const intervalo = cronDias.split("/")[1];
      this.valueACadaDias = { value: intervalo, label: `Dia ${intervalo} do mês` }
    },

    intervaloDiasDoMesEspecificos(cronDias) {
      const dias = cronDias.split(",").map(diaDaSemana => diaDaSemana.trim());
      this.valueDiasDoMesEspecificos = []

      for (let dia of dias) {
        this.valueDiasDoMesEspecificos.push({ value: dia, label: `Dia ${dia} do mês` })
      }
    },

    intervaloDiasDaSemanaEspecificos(cronDiasDaSemana) {
      const dias = cronDiasDaSemana.split(",").map(diaDaSemana => diaDaSemana.trim());
      this.valueDiasDaSemanaEspecificos = []

      for (let dia of dias) {
        this.valueDiasDaSemanaEspecificos.push(this.daysweekOptions[dia])
      }
    },
  },
}

</script>

<style>
.p-class-options {
  min-width: max-content;
  align-items: center;
  display: flex;
  margin-right: 5px;
  margin-left: 5px;
}

.checkbox-options {
  margin-top: 6px;
}

.multiselects-options {
  margin-top: 6px;
}

.disabled-item {
  color: rgb(175, 175, 175) !important;
}
</style>
