<template>
    <div class="clic-sidebar clic-sidebar-fixed is-light is-compact" id="clic-fw-menu-lateral" data-content="sidebarMenu">
        <div class="clic-sidebar-element clic-sidebar-head">
            <div class="clic-sidebar-brand">
                <a href="/" class="logo-link clic-sidebar-logo">
                    <img class="logo-light logo-img logo-img-lg" :src="logoSrc" alt="logo" />
                    <img class="logo-dark logo-img logo-img-lg" :src="logoSrc" alt="logo-dark" />
                    <img class="logo-small logo-img logo-img-small" :src="logoAppSrc" alt="logo-small" />
                </a>
            </div>
            <div class="clic-menu-trigger me-n2">
                <a href="#" v-on:click="$store.toggleMenu();" class="clic-nav-toggle clic-quick-nav-icon d-xl-none" data-target="sidebarMenu"><em class="icon ni ni-arrow-left"></em></a>
                <!-- <a href="#" class="clic-nav-compact clic-quick-nav-icon d-none d-xl-inline-flex" data-target="sidebarMenu"><em class="icon ni ni-menu"></em></a> --> 
            </div>
        </div>
        <div class="clic-sidebar-element">
            <div class="clic-sidebar-content">
                <div class="clic-sidebar-menu" data-simplebar>

                    <div class="simplebar-content-wrapper" tabindex="0" role="region" aria-label="scrollable content" style="height: 100%; overflow: hidden scroll;">
                        <div class="simplebar-content" style="padding: 24px 0px 48px;">
                        
                            <ul class="clic-menu">
                                <li
                                    v-for="menu in this.listaMenu || []" 
                                    v-if="menu.visible"
                                    :key="menu.id"
                                    class="clic-menu-item"
                                    :class="[menu.childrens ? 'has-sub':'', menu.expandido ? 'active':'' ]"
                                    style="padding: 2px 12px !important;"
                                >
                                    <b-link
                                        :to="{ name: menu.route}" 
                                        class="clic-menu-link" 
                                        :class="menu.childrens ? 'clic-menu-toggle':''"
                                        data-bs-original-title="" 
                                        title=""
                                        @click="acaoClick(menu);"
                                        style="padding: 0.525rem 28px 0.525rem 8px !important;"
                                    >
                                        <span class="clic-menu-icon">
                                            <v-icon 
                                                :name="menu.icon || 'bi-question-circle'"
                                                style="width: 30px; height: 30px;"
                                            />
                                        </span>
                                        <span class="clic-menu-text" style="text-align: left;">{{menu.label[$storage.getIdioma()] || menu.label['PT'] || menu.label}}</span>
                                    </b-link>
                                    <ul :class="menu.childrens ? 'clic-menu-sub' : '' " :style="menu.expandido ? 'display: block;' : '' ">
                                        <li
                                        v-for="submenu in menu.childrens || []"
                                        :key="submenu.id" 
                                        class="clic-menu-item"
                                        >
                                        <b-link
                                            v-if="submenu.visible"  
                                            :to="{ name: submenu.route}" 
                                            class="clic-menu-link"
                                            @click="acaoClick(submenu);"
                                            data-bs-original-title="" 
                                            title=""
                                        >
                                            <span class="clic-menu-text">{{submenu.label[$storage.getIdioma()] || submenu.label['PT'] || submenu.label}}</span>
                                        </b-link>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
export default {
    name: 'ClicMainSideMenu',
    methods: {

        obterMenusDisponiveisPorPerfil(menus){
            let dadosStorageNavegador      = localStorage.getItem(`${this.$host.subdominio}-clic-framework`);
            let usuarioLogado              = this.stringToObject(dadosStorageNavegador);
            let menusDisponiveisUsuario    = [];
            let { perfis : usuarioPerfis } = usuarioLogado != null ? usuarioLogado : {};

            if (menus && menus.length > 0) {
                menusDisponiveisUsuario = menus.filter(menu => {
                
                if (!usuarioPerfis || !menu.perfis || menu.perfis.length === 0) {
                    return true;// Incluir menu sem chave perfis ou com array perfis vazio
                };

                return usuarioPerfis && usuarioPerfis.length > 0 && menu.perfis.some(menuPerfil =>
                    usuarioPerfis.some(itemPerfilUsuario =>
                        (itemPerfilUsuario.tag === menuPerfil.tag) || (itemPerfilUsuario.tag === "ADM")
                    ));
                });
            };

            return menusDisponiveisUsuario;
        },

        stringToObject(string){
            try{
                return JSON.parse(string);
            }catch(_){
                return string;
            }
        },
        
        clicouItemMenuMethod() {
            this.$emit('clicouItemMenu');
        },

        expandirMenu(id) {
            let listaAtualizada = [];
            for (let itemMenu of this.listaMenu) {
                if  (id == itemMenu.id) {
                    itemMenu.expandido = !itemMenu.expandido;
                } else {
                    itemMenu.expandido = false;
                }
                listaAtualizada.push(itemMenu);
            }
            this.listaMenu = listaAtualizada;
        },

        recolherMenu(id) {
            let listaAtualizada = [];
            for (let itemMenu of this.listaMenu) {
                if  (id == itemMenu.id) {
                    itemMenu.expandido = false;
                }
                listaAtualizada.push(itemMenu);
            }
            this.listaMenu = listaAtualizada;
        },

        acaoClick(menu) {
            if  (menu.childrens) {
                this.expandirMenu(menu.id);
            } else {
                this.$root.$emit('clic:menu:toggle');
                

                //let menu = document.getElementById("clic-fw-menu-lateral");
                //menu.classList.remove("clic-sidebar-active");

                ///let ariaExpanded = document.getElementById("btntogglenavbar").getAttribute('aria-expanded');
                ///if  (ariaExpanded == 'true') {
                ///this.$root.$emit('bv::toggle::collapse', 'nav-collapse');
                //let navigation = document.querySelector('.navigation');
                //navigation.classList.remove("active");
                ///}
            }
            
            if  (menu.onClick) {
                try {
                    eval(menu.onClick);
                } catch (e) {
                    console.error(`[ClicSideBarMenu] Falha ao executar o evento 'onClick'. Detalhes: ${e}`);
                }
            }
        },

    },

    data() {
        return {
            listaMenu : [],
            logoSrc : `${process.env.VUE_APP_BUCKET_URL}/${this.$host.subdominio}/imagens/identidade/logo.png`,
            logoAppSrc : `${process.env.VUE_APP_BUCKET_URL}/${this.$host.subdominio}/imagens/identidade/logoapp.png`
        }
    },
  
    mounted() {
    },
    // TO DO: Ajustar criacao de menus, precisa sempre que for monta-lo, utilizar a funcao do router.
    created() {
        for (let itemMenu of this.obterMenusDisponiveisPorPerfil(this.$menus)) {
            itemMenu.expandido = false;
            this.listaMenu.push(itemMenu);
        }
    },


    props: {
        cp:{
            type: Object
        },
        idTela: {
            type: String
        }
    },

    navbar: {
        type : Array
    }
}

</script>

<style scoped>

    .clic-sidebar .logo-img-small {
        opacity: 0;
        max-height: 36px;
        max-width: 36px;
        left: 6.4px !important;
    }

    .clic-sidebar-head {
        padding: 14px 24px 0px 12px;
    }

    .logo-img-lg {
        max-height: 50px;
        max-width: 140px;
    }

</style>
