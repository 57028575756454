import ClicColInterno from '../clicGridLayout/ClicColInternoModel'
import ClicCodeReaderDocs from './ClicCodeReaderDocs';

export default {
    ClicCodeReader : {
        config      : {isContainer: false, icon:"md-calendarmonth-twotone", documentation: ClicCodeReaderDocs.documentation },
        component   : {type: 'string', component: { name : '' }, disabled:true},
        id          : {type: 'string', component: { name : '' } },
        
        props : {
            scannerOptions  : { type: 'array',   default: undefined,        component: { name : 'multiselect', labels: "text", multiple: true, trackBy: "id", values: ["BARCODE", "QRCODE"] } },
            ...ClicColInterno,

        },
        events : {
            onCodeScan       : {type: 'string', component: { name : 'code' } }
        }
    }
}









