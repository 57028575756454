import ClicColInterno from '../clicGridLayout/ClicColInternoModel'
import ClicCronBuilderDocs from './ClicCronBuilderDocs';

export default {
    ClicCronBuilder : {
        config      : {isContainer: false, icon:"md-calendarmonth-twotone", documentation: ClicCronBuilderDocs.documentation },
        component   : {type: 'string', component: { name : '' }, disabled:true},
        id          : {type: 'string', component: { name : '' } },
        
        props : {
            ...ClicColInterno,

        },
        events : {
            onEventClick       : {type: 'string', component: { name : 'code' } },
        }
    }
}









