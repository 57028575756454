<template>
    <ClicColInterno :cp="cp" :key="componentKey">
        <div class="form-group left-label">

            <div class="d-flex flex-row align-items-start">
                <label class="form-label form-label-clic" v-if="label">
                    <b v-if="required && (value == undefined || value === '')" class="text-danger">*</b>
                    {{ $storage.getTextoTraduzido(label) }}
                </label>
                <ClicHintInterno v-if="hintText" :cp="cp" />
            </div>

            <multiselect :id="id" :style="this.style" :class="fieldState === false ? 'invalid-field' : this.class"
                :options="options" :multiple="multiple" :trackBy="trackBy" :label="labels" :searchable="searchable"
                :clearOnSelect="clearOnSelect" :hideSelected="hideSelected"
                :placeholder="$storage.getTextoTraduzido(placeholder)" :allowEmpty="allowEmpty" :resetAfter="resetAfter"
                :closeOnSelect="closeOnSelect" :customLabel="customLabel" :taggable="taggable"
                :tagPlaceholder="tagPlaceholder" :tagPosition="tagPosition" :max="max" :optionsLimit="optionsLimit"
                :groupValues="groupValues" :groupLabel="groupLabel" :groupSelect="groupSelect" :blockKeys="blockKeys"
                :internalSearch="internalSearch" :preserveSearch="preserveSearch" :preselectFirst="preselectFirst"
                :name="name" :selectLabel="selectLabel" :selectGroupLabel="selectGroupLabel"
                :selectedLabel="selectedLabel" :deselectLabel="deselectLabel" :deselectGroupLabel="deselectGroupLabel"
                :showLabels="showLabels" :limit="limit" :limitText="limitText" :loading="loading" :disabled="disabled"
                :maxHeight="maxHeight" :openDirection="openDirection" :showNoResults="showNoResults"
                :tabindex="tabindex" :showPointer="showPointer" :optionHeight="optionHeight" v-model="value"
                :ref="cp.id" @input="input" @select="select" @remove="remove" @search-change="searchChange" @tag="tag"
                @open="open" @close="close" :internal-search="axios ? false : true">
                <template v-slot:noResult>Nenhum registro localizado</template>
                <template v-slot:noOptions>Sem registros</template>
                <template v-slot:option="{option, search}" v-if="$getSlot('option')">
                    <MotorS 
                        :cp="cp" 
                        :childrens="$getSlot('option')"
                        :idTela="idTela"
                        :state="{...state, option, search}"
                    />
                </template>

                <template v-slot:singleLabel="{ option, search }" v-if="$getSlot('option')" style="text: white">
                    <MotorS :cp="cp" :childrens="$getSlot('option')" :idTela="idTela"
                        :state="{ ...state, option, search, a: 1 }" />
                </template>



            </multiselect>
            <h5 v-if="fieldState === false && invalidFeedbackMessage" id="input-live-feedback"
                class="text-danger invalid-feedback-message">
                {{ invalidFeedbackMessage }}
            </h5>
        </div>
    </ClicColInterno>
</template>

<script>

import ComponentMixin from '../../core/ComponentMixin';
import Multiselect from 'vue-multiselect';

export default {
    name: 'ClicMultiselect',
    mixins: [ComponentMixin],
    components: { Multiselect },
    data() {
        return {
            public: {
                dataRefresh: this.atualizarConsulta,
                isValid: this.validateField,
                clearField: this.clearField,
                undo: this.undo,
                apply: this.apply,
                isValueChanged: this.isValueChanged,
                focus: this.focus
            },
            parametrosConsulta: {},
            loading: false,
            value: undefined,
            oldValue: undefined,
            fieldState: undefined,
            id: this.$utils.getComponentID(),
            fetch: 10,
            timer: null,
            multiselectWidth: 0,
        }
    },

    created() {
        if (this.axios) {
            this.realizarConsulta(this.axios);
        }
    },

    mounted() {
        this.oldValue = this.value;
    },

    methods: {
        input(value, id) {
            try {
                if (this.lodash.has(this.cp, 'events.input')) {
                    eval(this.cp.events.input);
                }
            } catch (e) {
                console.error(`[ClicMultiselect] Falha ao executar evendo 'input'. Detalhes: ${e}`);
            }
        },

        select(selectedOption, id) {

            if (this.changeBackgroundColor) {
                const multiselectTags = document.querySelector('.multiselect__tags');
                if (multiselectTags) {
                    multiselectTags.style.backgroundColor = selectedOption[`${this.backgroundColorProp}`]  || '';
                    multiselectTags.style.color = 'white';
                }
                const multiselectSpinner = document.querySelector('.multiselect__spinner');
                if (multiselectSpinner) {
                    multiselectSpinner.style.backgroundColor = selectedOption[`${this.backgroundColorProp}`] || '';
                }  
            }

                try {
                    if (!this.fieldState || this.fieldState) {
                        this.fieldState = undefined;
                    };

                    if (this.lodash.has(this.cp, 'events.select')) {
                        eval(this.cp.events.select);

                    }
                } catch (e) {
                    console.error(`[ClicMultiselect] Falha ao executar evendo 'select'. Detalhes: ${e}`);
                }
            },

            remove(removedOption, id) {
                try {
                    if (this.lodash.has(this.cp, 'events.remove')) {
                        eval(this.cp.events.remove);
                    }
                } catch (e) {
                    console.error(`[ClicMultiselect] Falha ao executar evendo 'remove'. Detalhes: ${e}`);
                }
            },

            searchChange(searchQuery, id) {
                try {
                    if (this.axios) {
                        this.options = [];
                        let pathRestAxios = this.axios.method && this.axios.method.toLowerCase() == "get" ? "params" : "data";
                        this.fetch = this.lodash.get(this.axios[pathRestAxios], "fetch", this.fetch);

                        if (this.timer) {
                            clearTimeout(this.timer);
                            this.timer = null;
                        };
                        this.timer = setTimeout(() => {
                            this.realizarConsulta(
                                {
                                    [pathRestAxios]: {
                                        [this.searchAtributeAxiosName || "search"]: searchQuery,
                                        fetch: this.fetch
                                    }
                                }
                            );
                        }, this.lodash.get(this.cp, "props.searchDelayInMs", 300));

                    };

                    if (this.lodash.has(this.cp, 'events.searchChange')) {
                        eval(this.cp.events.searchChange);
                    }

                } catch (e) {
                    console.error(`[ClicMultiselect] Falha ao executar evento 'searchChange'. Detalhes: ${e}`);
                }

            },

            tag(searchQuery, id) {
                try {
                    if (this.lodash.has(this.cp, 'events.tag')) {
                        eval(this.cp.events.tag);
                    }
                } catch (e) {
                    console.error(`[ClicMultiselect] Falha ao executar evento 'tag'. Detalhes: ${e}`);
                }
            },

            open(id) {
                try {
                    this.ajustarPosicaoListaItens();

                    if (this.lodash.has(this.cp, 'events.open')) {
                        eval(this.cp.events.open);
                    };

                } catch (e) {
                    console.error(`[ClicMultiselect] Falha ao executar evendo 'open'. Detalhes: ${e}`);
                }
            },

            close(value, id) {
                try {
                    this.ajustarPosicaoListaItens();

                    if (this.lodash.has(this.cp, 'props.validateScript')) {
                        this.validateField();
                    };
                    if (this.lodash.has(this.cp, 'events.close')) {
                        eval(this.cp.events.close);
                    }
                } catch (e) {
                    console.error(`[ClicMultiselect] Falha ao executar evendo 'close'. Detalhes: ${e}`);
                }
            },

            atualizarConsulta(parametros) {
                this.realizarConsulta(parametros);
            },

            realizarConsulta(parametros = this.axios) {
                if (parametros) {
                    this.parametrosConsulta = {
                        ...this.lodash.merge(this.axios, parametros)
                    };

                    this.loading = true;
                    this.$axios(this.parametrosConsulta).then((response) => {
                        this.loading = false;
                        this.options = this.responsePath ? response.data[this.responsePath] : response.data || [];
                    });
                }
            },

            ajustarPosicaoListaItens() {
                const el = document.getElementById(this.id);
                const parent = el.closest('.multiselect');

                if (this.multiselectWidth == 0) {
                    this.multiselectWidth = parent.clientWidth
                };

                this.$nextTick(() => {
                    parent.style.minWidth = `${this.multiselectWidth}px`;
                    parent.style.width = `${this.multiselectWidth}px`;
                });
            },

            undo() {
                this.value = this.oldValue;
            },
            apply() {
                this.oldValue = this.value;
            },
            isValueChanged() {
                return this.oldValue != this.value;
            },

            focus() {
                this.$refs[this.cp.id].$el.focus()
            },

        },

    }
</script>


<style>
.multiselect {
    height: 100% !important;
}

.invalid-feedback-message {
    padding-top: 4px;
    font-size: 11.2px;
    font-weight: 100;
}

.invalid-field .multiselect__tags {
    border-color: red
}

fieldset[disabled] .multiselect {
    pointer-events: none
}

.multiselect__spinner {
    position: absolute;
    right: 1px;
    top: 1px;
    width: 48px;
    height: 35px;
    background: #fff;
    display: block
}

.multiselect__spinner:after,
.multiselect__spinner:before {
    position: absolute;
    content: "";
    top: 50%;
    left: 50%;
    margin: -8px 0 0 -8px;
    width: 16px;
    height: 16px;
    border-radius: 100%;
    border: 2px solid transparent;
    border-top-color: var(--cor-principal);
    box-shadow: 0 0 0 1px transparent
}

.multiselect__spinner:before {
    animation: spinning 2.4s cubic-bezier(.41, .26, .2, .62);
    animation-iteration-count: infinite
}

.multiselect__spinner:after {
    animation: spinning 2.4s cubic-bezier(.51, .09, .21, .8);
    animation-iteration-count: infinite
}

.multiselect__loading-enter-active,
.multiselect__loading-leave-active {
    transition: opacity .4s ease-in-out;
    opacity: 1
}

.multiselect__loading-enter,
.multiselect__loading-leave-active {
    opacity: 0
}

.multiselect,
.multiselect__input,
.multiselect__single {
    font-family: inherit;
    font-size: 16px;
    -ms-touch-action: manipulation;
    touch-action: manipulation
}

.multiselect {
    box-sizing: content-box;
    display: block;
    position: relative;
    width: 100% !important;
    min-height: 38px;
    min-width: 100%;
    text-align: left;
    color: #35495e;
}

.multiselect * {
    box-sizing: border-box
}

.multiselect:focus {
    outline: none
}

.multiselect--disabled {
    background: #ededed;
    pointer-events: none;
    opacity: .6
}

.multiselect--active {
    z-index: 50
}

.multiselect--active:not(.multiselect--above) .multiselect__current,
.multiselect--active:not(.multiselect--above) .multiselect__input,
.multiselect--active:not(.multiselect--above) .multiselect__tags {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0
}

.multiselect--active .multiselect__select {
    transform: rotate(180deg)
}

.multiselect--above.multiselect--active .multiselect__current,
.multiselect--above.multiselect--active .multiselect__input,
.multiselect--above.multiselect--active .multiselect__tags {
    border-top-left-radius: 0;
    border-top-right-radius: 0
}

.multiselect__input,
.multiselect__single {
    position: relative;
    display: inline-block;
    min-height: 20px;
    line-height: 20px;
    border: none;
    border-radius: 5px;
    background: #fff;
    padding: 0 0 0 5px;
    width: 100%;
    transition: border .1s ease;
    box-sizing: border-box;
    margin-bottom: 8px;
    vertical-align: top
}

.multiselect__input:-ms-input-placeholder {
    color: #35495e
}

.multiselect__input::placeholder {
    color: #35495e
}

.multiselect__tag~.multiselect__input,
.multiselect__tag~.multiselect__single {
    width: auto
}

.multiselect__input:hover,
.multiselect__single:hover {
    border-color: #cfcfcf
}

.multiselect__input:focus,
.multiselect__single:focus {
    border-color: #a8a8a8;
    outline: none
}

.multiselect__single {
    padding-left: 5px;
    margin-bottom: 8px;
    white-space: nowrap;
    overflow: hidden;
}

.multiselect__tags-wrap {
    display: inline
}

.multiselect__tags {
    min-height: 38px;
    display: block;
    padding: 8px 38px 0 8px;
    border-radius: 5px;
    border: 1px solid #e8e8e8;
    background: #fff;
    font-size: 14px
}

.multiselect__tag {
    position: relative;
    display: inline-block;
    padding: 4px 26px 4px 10px;
    border-radius: 5px;
    margin-right: 10px;
    color: #fff;
    line-height: 1;
    background: var(--cor-principal);
    margin-bottom: 5px;
    white-space: nowrap;
    overflow: hidden;
    max-width: 100%;
    text-overflow: ellipsis
}

.multiselect__tag-icon {
    cursor: pointer;
    margin-left: 7px;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    font-weight: 700;
    font-style: normal;
    width: 22px;
    text-align: center;
    line-height: 22px;
    transition: all .2s ease;
    border-radius: 5px
}

.multiselect__tag-icon:after {
    content: "\D7";
    color: #266d4d;
    font-size: 14px
}

.multiselect__tag-icon:focus,
.multiselect__tag-icon:hover {
    background: #369a6e
}

.multiselect__tag-icon:focus:after,
.multiselect__tag-icon:hover:after {
    color: #fff
}

.multiselect__current {
    min-height: 38px;
    overflow: hidden;
    padding: 8px 30px 0 12px;
    white-space: nowrap;
    border-radius: 5px;
    border: 1px solid #e8e8e8
}

.multiselect__current,
.multiselect__select {
    line-height: 16px;
    box-sizing: border-box;
    display: block;
    margin: 0;
    text-decoration: none;
    cursor: pointer
}

.multiselect__select {
    position: absolute;
    width: 40px;
    height: 38px;
    right: 1px;
    top: 1px;
    padding: 4px 8px;
    text-align: center;
    transition: transform .2s ease
}

.multiselect__select:before {
    position: relative;
    right: 0;
    top: 65%;
    color: #999;
    margin-top: 4px;
    border-color: #999 transparent transparent;
    border-style: solid;
    border-width: 5px 5px 0;
    content: ""
}

.multiselect__placeholder {
    color: #adadad;
    /*display:inline-block;*/
    margin-bottom: 10px;
    padding-top: 2px;
}

.multiselect--active .multiselect__placeholder {
    display: none
}

.multiselect__content-wrapper {
    position: absolute;
    display: block;
    background: #fff;
    width: 100%;
    max-height: 240px;
    overflow: auto;
    border: 1px solid #e8e8e8;
    border-top: none;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    z-index: 50;
    -webkit-overflow-scrolling: touch
}

.multiselect__content {
    list-style: none;
    display: inline-block;
    padding: 0;
    margin: 0;
    min-width: 100%;
    vertical-align: top
}

.multiselect--above .multiselect__content-wrapper {
    bottom: 100%;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom: none;
    border-top: 1px solid #e8e8e8
}

.multiselect__content::webkit-scrollbar {
    display: none
}

.multiselect__element {
    display: block
}

.multiselect__option {
    display: block;
    padding: 12px;
    min-height: 38px;
    line-height: 16px;
    text-decoration: none;
    text-transform: none;
    vertical-align: middle;
    position: relative;
    cursor: pointer;
    white-space: nowrap
}

.multiselect__option:after {
    top: 0;
    right: 0;
    position: absolute;
    line-height: 38px;
    padding-right: 12px;
    padding-left: 20px;
    font-size: 13px
}

.multiselect__option--highlight {
    background: var(--cor-principal);
    outline: none;
    color: #fff
}

.multiselect__option--highlight:after {
    content: attr(data-select);
    background: var(--cor-principal);
    color: #fff
}

.multiselect__option--selected {
    background: #f3f3f3;
    color: #35495e;
    font-weight: 700
}

.multiselect__option--selected:after {
    content: attr(data-selected);
    color: silver
}

.multiselect__option--selected.multiselect__option--highlight {
    background: #ff6a6a;
    color: #fff
}

.multiselect__option--selected.multiselect__option--highlight:after {
    background: #ff6a6a;
    content: attr(data-deselect);
    color: #fff
}

.multiselect--disabled .multiselect__current,
.multiselect--disabled .multiselect__select {
    background: #ededed;
    color: #a6a6a6
}

.multiselect__option--disabled {
    background: #ededed !important;
    color: #a6a6a6 !important;
    cursor: text;
    pointer-events: none
}

.multiselect__option--group {
    background: #ededed;
    color: #35495e
}

.multiselect__option--group.multiselect__option--highlight {
    background: #35495e;
    color: #fff
}

.multiselect__option--group.multiselect__option--highlight:after {
    background: #35495e
}

.multiselect__option--disabled.multiselect__option--highlight {
    background: #dedede
}

.multiselect__option--group-selected.multiselect__option--highlight {
    background: #ff6a6a;
    color: #fff
}

.multiselect__option--group-selected.multiselect__option--highlight:after {
    background: #ff6a6a;
    content: attr(data-deselect);
    color: #fff
}

.multiselect-enter-active,
.multiselect-leave-active {
    transition: all .15s ease
}

.multiselect-enter,
.multiselect-leave-active {
    opacity: 0
}

.multiselect__strong {
    margin-bottom: 8px;
    line-height: 20px;
    display: inline-block;
    vertical-align: top
}

[dir=rtl] .multiselect {
    text-align: right
}

[dir=rtl] .multiselect__select {
    right: auto;
    left: 1px
}

[dir=rtl] .multiselect__tags {
    padding: 8px 8px 0 38px
}

[dir=rtl] .multiselect__content {
    text-align: right
}

[dir=rtl] .multiselect__option:after {
    right: auto;
    left: 0
}

[dir=rtl] .multiselect__clear {
    right: auto;
    left: 12px
}

[dir=rtl] .multiselect__spinner {
    right: auto;
    left: 1px
}

@keyframes spinning {
    0% {
        transform: rotate(0)
    }

    to {
        transform: rotate(2turn)
    }
}



span.multiselect__single {
    background-color: unset;
}
</style>