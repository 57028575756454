import ClicColInterno from '../clicGridLayout/ClicColInternoModel'


import ClicMongoDBChartDocs from './ClicMongoDBChartDocs';

export default {
    ClicMongoDBChart : {
        config      : {icon:"md-stackedbarchart-round", documentation: ClicMongoDBChartDocs.documentation },
        component   : {type: 'string', component: { name : '' }, disabled:true},
        id          : {type: 'string', component: { name : '' } },
        
        props : {

            type                    : { type: 'string',      default: "dashboard",  component: { name : 'select', values: ['dashboard', 'chart'] } },
            baseUrl                 : { type: 'string',      default: undefined,    component: { name : 'input' } },
            chartId                 : { type: 'string',      default: undefined,    component: { name : 'input' } },
            dashboardId             : { type: 'string',      default: undefined,    component: { name : 'input' } },

            ...ClicColInterno,

        },
        events : {

        }
    }
}









